import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
/* import { useNavigate } from "react-router-dom"; */
import moment from "moment";
import {
  setFormTrackInfo,
  setNewNaturalStep,
} from "redux/features/newNaturalClient";
import { postAddressInformation } from "service/newNaturalClient/formOne";
import { getAllValuesFromForm } from "helpers/getAllValuesFromForm";
import { handleErrors } from "helpers/handleErrors";
export const structureInfoFromBack = (trackData, formState) => {
  const saveInfo = trackData.data;
  const newObject = {};
  Object.entries(formState).map(([key, value]) => {
    const newValue = value;
    newValue.value = saveInfo[key];
    if (value.type === "date")
      newValue.value = moment(saveInfo[key]).format("YYYY-MM-DD");
    if (value.type === "radio" && typeof saveInfo[key] === "boolean")
      newValue.value = saveInfo[key] ? "yes" : "no";
    newObject[key] = newValue;
  });
  return newObject;
};

export const useAddressDataStep = () => {
  const initialState2 = {
    countryResident: {
      label: "",
      placeholder: "País de residencia",
      name: "countryResident",
      // value: "192",
      value: "1",
      required: true,
      error: "",
      visibility: "",
      type: "select",
      options: [],
      rule: (value) => {
        if (value === "") return "Debe de completar este campo";
        return "";
      },
    },
    cityResident: {
      label: "",
      placeholder: "Ciudad",
      name: "cityResident",
      value: "",
      required: true,
      error: "",
      visibility: "",
      type: "select",
      options: [],
      rule: (value) => {
        if (value === "") return "Debe de completar este campo";
        return "";
      },
      disabled: false,
    },
    provinceResident: {
      label: "",
      placeholder: "Estado",
      name: "provinceResident",
      value: "",
      required: true,

      error: "",
      visibility: "",
      type: "select",
      options: [],
      rule: (value) => {
        if (value === "") return "Debe de completar este campo";
        return "";
      },
      disabled: false,
    },
    district: {
      label: "",
      placeholder: "Municipio",
      name: "district",
      value: "",
      required: true,

      error: "",
      visibility: "",
      type: "select",
      options: [],
      rule: (value) => {
        if (value === "") return "Debe de completar este campo";
        return "";
      },
      disabled: false,
    },
    // postalCode: {
    //   label: "",
    //   placeholder: "Cod. Postal",
    //   name: "postalCode",
    //   value: "",
    //   required: true,
    //   error: "",
    //   visibility: "",
    //   type: "select",
    //   rule: (value) => {
    //     const regex = /^[A-Za-z0-9]+$/;
    //     if (value === "") return "Debe de completar este campo";
    //     if (!regex.test(value))
    //       return "Este campo es alfa numérico y puede contener guiones";
    //     return "";
    //   },
    // },
    address: {
      label: "",
      placeholder: "Dirección",
      name: "address",
      value: "",
      required: true,
      error: "",
      visibility: "",
      type: "text",
      rule: (value) => {
        if (value === "") return "Debe de completar este campo";
        if (value.length > 250)
          return "La dirección solo puede tener un máximo de 250 caracteres";
        return "";
      },
    },
  };
  const [homeAdress, setHomeAdress] = useState(initialState2);

  const dispatch = useDispatch();
  /*   const navigate = useNavigate(); */
  const { trackData } = useSelector((store) => store.newNaturalClient);

  const handleInitialStateInfo = () => {
    const formAdress = structureInfoFromBack(trackData, initialState2);
    setHomeAdress(formAdress);
  };

  useEffect(() => {
    if (Object.keys(trackData.data).length > 1) handleInitialStateInfo();
    return () => {
      setHomeAdress(initialState2);
    };
  }, [trackData.data]);

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const values = {
        ...getAllValuesFromForm(homeAdress),
      };
      const formData = new FormData();
      Object.entries(values).forEach(([key, value]) => {
        formData.append(key, value);
      });
      const res = await postAddressInformation(formData);
      dispatch(setNewNaturalStep(parseInt(res.data.nextView)));
      dispatch(setFormTrackInfo(res.data));
      /* navigate(".", { state: res.data }); */
    } catch (error) {
      handleErrors(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    handleSubmit,
    homeAdress,
    setHomeAdress,
  };
};
